import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Add.css';
import Modal from 'react-modal';
import cloneextend from 'cloneextend';

export default class Add extends Component {
  static propTypes = {
    fields: PropTypes.array.isRequired,
    saveExtraFields: PropTypes.func.isRequired,
    reminders: PropTypes.object,
    remindersChanged: PropTypes.func,
  };

  state = {
    modalOpen: false,
    fields: [],
    newFieldTitle: '',
    newFieldType: 'Text',
  };

  emptyReminder = {
    date1: {
      emails: [],
      dateToSend: null,
      message: '',
    },
    date2: {
      emails: [],
      dateToSend: null,
      message: '',
    },
    date3: {
      emails: [],
      dateToSend: null,
      message: '',
    },
  };

  componentWillMount() {
    this.setState({fields: this.props.fields});
  }

  componentWillUpdate(nextProps) {
    // if (this.props.campaignId !== nextProps.campaignId) {
    //   this.setInitialState(nextProps);
    // }
  }

  editExtraFieldsClicked() {
    this.setState({modalOpen: true});
  }

  closeModalClicked() {
    this.setState({modalOpen: false});
    this.props.saveExtraFields(this.state.fields);
  }

  fieldTitleChanged(index) {
    const fields = [ ...this.state.fields ];
    fields[index].fieldTitle = this.refs['fieldTitle' + index].value;
    this.setState({fields});

    // Rename the reminder
    if (fields[index].fieldType === 'DateWithReminder') {
      this.renameReminder(index, fields);
    }
  }

  renameReminder(index, fields) {
    const reminders = cloneextend.clone(this.props.reminders);
    reminders[fields[index].fieldTitle]
      = reminders[this.state.fields[index].fieldTitle];
    if (reminders[this.state.fields[index].fieldTitle]) {
      delete reminders[this.state.fields[index].fieldTitle];
    }
    this.props.remindersChanged(
      this.state.fields[index].fieldTitle,
      reminders[this.state.fields[index].fieldTitle]
    );
    this.props.remindersChanged(
      fields[index].fieldTitle,
      reminders[fields[index].fieldTitle]
    );
  }

  removeStaleReminder(index, fields) {
    const reminders = cloneextend.clone(this.props.reminders);
    if (reminders[fields[index].fieldTitle]) {
      delete reminders[fields[index].fieldTitle];
    }
    this.props.remindersChanged(
      this.state.fields[index].fieldTitle,
      reminders[this.state.fields[index].fieldTitle]
    );
  }

  addEmptyReminder(index, fields) {
    const reminders = cloneextend.clone(this.props.reminders);
    if (reminders[fields[index].fieldTitle] === undefined) {
      reminders[fields[index].fieldTitle]
        = cloneextend.clone(this.emptyReminder);
    }

    this.props.remindersChanged(
      fields[index].fieldTitle,
      reminders[fields[index].fieldTitle]
    );
  }

  fieldTypeChanged(index) {
    const fields = [ ...this.state.fields ];
    fields[index].fieldType = this.refs['fieldType' + index].value;
    this.setState({fields});

    if (this.state.fields[index].fieldType === 'DateWithReminder') {
      this.removeStaleReminder(index, fields);
    }
    if (fields[index].fieldType === 'DateWithReminder') {
      this.addEmptyReminder(index, fields);
    }

    if (fields[index].fieldType !== 'Text') {
      fields[index].value = ''; // reset value to prevent an invalid date
    }
  }

  deleteField(index) {
    const fields = [ ...this.state.fields ];
    fields.splice(index, 1);
    this.setState({fields});
  }

  addNewField() {
    const fields = [ ...this.state.fields ];
    fields.push({
      fieldTitle: this.refs.newFieldTitle.value,
      fieldType: this.refs.newFieldType.value,
      value: '',
    });
    this.setState({
      fields,
      newFieldTitle: '',
      newFieldType: 'Text',
    });

    this.addEmptyReminder(fields.length - 1, fields);
  }

  newFieldTitleChanged() {
    this.setState({newFieldTitle: this.refs.newFieldTitle.value});
  }

  newFieldTypeChanged() {
    this.setState({newFieldType: this.refs.newFieldType.value});
  }

  render() {
    return (
      <div className="Add">
        <Modal
          isOpen={this.state.modalOpen}
          onAfterOpen={()=>{}}
          onRequestClose={()=>{}}
          className={{
            base: 'extraFieldsModalBase',
          }}
          overlayClassName={{
            base: 'extraFieldsModalBaseOverlay',
          }}
          contentLabel="Modal">
          <h2>Edit Extra Fields</h2>

          <div>Edit</div>

          {this.state.fields.map((field, index) => {
            return (
              <div key={index} className="Add_row">
                <div>
                  <input
                    type="text"
                    ref={'fieldTitle' + index}
                    onChange={this.fieldTitleChanged.bind(this, index)}
                    value={field.fieldTitle} />
                </div>
                <div>
                  <select
                    className="Add_select"
                    value={field.fieldType}
                    ref={'fieldType' + index}
                    onChange={this.fieldTypeChanged.bind(this, index)} >
                    <option value="Text">Text</option>
                    <option value="Date">Date</option>
                    <option value="DateWithReminder">Date With Reminder</option>
                  </select>
                </div>
                <div>
                  <button onClick={this.deleteField.bind(this, index)}>
                    Delete Field
                  </button>
                </div>
              </div>
            );
          })}

          <div className="Add_row">
            <div>
              <input
                type="text"
                onChange={this.newFieldTitleChanged.bind(this)}
                value={this.state.newFieldTitle}
                ref="newFieldTitle" />
            </div>
            <div>
              <select
                className="Add_select"
                onChange={this.newFieldTypeChanged.bind(this)}
                value={this.state.newFieldType}
                ref="newFieldType" >
                <option value="Text">Text</option>
                <option value="Date">Date</option>
                <option value="DateWithReminder">Date With Reminder</option>
              </select>
            </div>
            <div>
              <button
                onClick={this.addNewField.bind(this)}>
                Add New Field
              </button>
            </div>
          </div>

          <div className="Add_modalFooter">
            <button
              className="Add_closeButton"
              onClick={this.closeModalClicked.bind(this)}>
              Close
            </button>
          </div>
        </Modal>

        <button
          onClick={this.editExtraFieldsClicked.bind(this)}>
          Edit Extra Fields
        </button>
      </div>
    );
  }
}
