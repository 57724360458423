import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Specification.css";
import DateField from "../DateField/DateField";
import ExtraFields from "./ExtraFields/ExtraFields";
import clientConfig from "../../../../clientConfig";
import cloneextend from "cloneextend";
import { post } from "../../../../helpers/api";
import AutoComplete from "../AutoComplete/AutoComplete";

export default class Specification extends Component {
  static propTypes = {
    autocompleteData: PropTypes.object.isRequired,
    campaignId: PropTypes.number.isRequired,
    campaignName: PropTypes.string,
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    deleteSpecification: PropTypes.func.isRequired,
    saveForm: PropTypes.func.isRequired,
    reminders: PropTypes.object,
    saveSpecificationData: PropTypes.func.isRequired,
    specification: PropTypes.object.isRequired
  };

  state = {
    id: "",
    new: true,
    title: "",
    bermudaJobNumber: "",
    grjJobNoPrint: "",
    grjJobNoPaper: "",
    grjJobNoProof: "",
    grjPoNumber: "",
    designer: "",
    quantity: "",
    versions: "",
    pageSize: "",
    textPages: "",
    coverPages: "",
    noOfColours: "",
    binding: "",
    spineWidth: "",
    weight: "",
    deliveryNote: "",
    quotePaper: "",
    quotePrint: "",
    quoteTotal: "",
    printer: "",
    fileToBdp: "",
    fileToPrint: "",
    printToMailHouse: "",
    signOffTime: "",
    signOffComment: "",
    signOffDate: "",
    extraFields: [],
    confirmationFile: "",
    orderFile: "",
    invoiceNumber: "",
    makingInvoice: false,
    invoiceLink: "",
    invoiceLinkProof: ""
  };

  componentWillMount() {
    this.setState(this.props.specification);
  }

  componentWillUpdate(nextProps) {
    if (this.props.specification.id !== nextProps.specification.id) {
      this.setState(nextProps.specification);
    }
  }

  titleChanged(title) {
    const state = { ...this.state };
    state.title = title;
    this.props.saveSpecificationData(state);
    this.setState({ title: title });
  }

  quantityChanged(quantity) {
    const state = { ...this.state };
    state.quantity = quantity;
    this.props.saveSpecificationData(state);
    this.setState({ quantity: quantity });
  }

  versionsChanged(versions) {
    const state = { ...this.state };
    state.versions = versions;
    this.props.saveSpecificationData(state);
    this.setState({ versions: versions });
  }

  pageSizeChanged(pageSize) {
    const state = { ...this.state };
    state.pageSize = pageSize;
    this.props.saveSpecificationData(state);
    this.setState({ pageSize: pageSize });
  }

  textPagesChanged(textPages) {
    const state = { ...this.state };
    state.textPages = textPages;
    this.props.saveSpecificationData(state);
    this.setState({ textPages: textPages });
  }

  coverPagesChanged(coverPages) {
    const state = { ...this.state };
    state.coverPages = coverPages;
    this.props.saveSpecificationData(state);
    this.setState({ coverPages: coverPages });
  }

  noOfColoursChanged(noOfColours) {
    const state = { ...this.state };
    state.noOfColours = noOfColours;
    this.props.saveSpecificationData(state);
    this.setState({ noOfColours: noOfColours });
  }

  bindingChanged(binding) {
    const state = { ...this.state };
    state.binding = binding;
    this.props.saveSpecificationData(state);
    this.setState({ binding: binding });
  }

  deliveryNoteChanged() {
    const state = { ...this.state };
    state.deliveryNote = this.refs.deliveryNote.value;
    this.props.saveSpecificationData(state);
    this.setState({ deliveryNote: this.refs.deliveryNote.value });
  }

  weightChanged(weight) {
    const state = { ...this.state };
    state.weight = weight;
    this.props.saveSpecificationData(state);
    this.setState({ weight: weight });
  }

  spineWidthChanged(spineWidth) {
    const state = { ...this.state };
    state.spineWidth = spineWidth;
    this.props.saveSpecificationData(state);
    this.setState({ spineWidth: spineWidth });
  }

  bermudaJobNumberChanged(bermudaJobNumber) {
    const state = { ...this.state };
    state.bermudaJobNumber = bermudaJobNumber;
    this.props.saveSpecificationData(state);
    this.setState({ bermudaJobNumber: bermudaJobNumber });
  }

  grjJobNoPrintChanged(grjJobNoPrint) {
    const state = { ...this.state };
    state.grjJobNoPrint = grjJobNoPrint;
    this.props.saveSpecificationData(state);
    this.setState({ grjJobNoPrint: grjJobNoPrint });
  }

  grjJobNoPaperChanged(grjJobNoPaper) {
    const state = { ...this.state };
    state.grjJobNoPaper = grjJobNoPaper;
    this.props.saveSpecificationData(state);
    this.setState({ grjJobNoPaper: grjJobNoPaper });
  }

  grjJobNoProofChanged(grjJobNoProof) {
    const state = { ...this.state };
    state.grjJobNoProof = grjJobNoProof;
    this.props.saveSpecificationData(state);
    this.setState({ grjJobNoProof: grjJobNoProof });
  }

  grjPoNumberChanged(grjPoNumber) {
    const state = { ...this.state };
    state.grjPoNumber = grjPoNumber;
    this.props.saveSpecificationData(state);
    this.setState({ grjPoNumber: grjPoNumber });
  }

  designerChanged(designer) {
    const state = { ...this.state };
    state.designer = designer;
    this.props.saveSpecificationData(state);
    this.setState({ designer: designer });
  }

  signOffTimeChanged(signOffTime) {
    const state = { ...this.state };
    state.signOffTime = signOffTime;
    this.props.saveSpecificationData(state);
    this.setState({ signOffTime: signOffTime });
  }

  signOffCommentChanged(signOffComment) {
    const state = { ...this.state };
    state.signOffComment = signOffComment;
    this.props.saveSpecificationData(state);
    this.setState({ signOffComment: signOffComment });
  }

  signOffDateChanged(newDate) {
    this.dateChanged(newDate, "signOffDate");
    const state = cloneextend.clone(this.state);
    state.signOffDate = newDate.format("YYYY-MM-DD");
    this.props.saveSpecificationData(state);
    this.setState({ signOffDate: newDate.format("YYYY-MM-DD") });
  }

  quotePaperChanged(quotePaper) {
    const state = { ...this.state };
    state.quotePaper = quotePaper;
    this.props.saveSpecificationData(state);
    this.setState({ quotePaper: quotePaper });
  }

  quotePrintChanged(quotePrint) {
    const state = { ...this.state };
    state.quotePrint = quotePrint;
    this.props.saveSpecificationData(state);
    this.setState({ quotePrint: quotePrint });
  }

  quoteTotalChanged(quoteTotal) {
    const state = { ...this.state };
    state.quoteTotal = quoteTotal;
    this.props.saveSpecificationData(state);
    this.setState({ quoteTotal: quoteTotal });
  }

  printerChanged(printer) {
    const state = { ...this.state };
    state.printer = printer;
    this.props.saveSpecificationData(state);
    this.setState({ printer: printer });
  }

  replaceDateReminders(reminder, newDate) {
    reminder.date1.dateToSend = newDate
      .clone()
      .add(clientConfig.reminders.date1, "days")
      .format("YYYY-MM-DD");
    reminder.date2.dateToSend = newDate
      .clone()
      .add(clientConfig.reminders.date2, "days")
      .format("YYYY-MM-DD");
    reminder.date3.dateToSend = newDate
      .clone()
      .add(clientConfig.reminders.date3, "days")
      .format("YYYY-MM-DD");
    return reminder;
  }

  dateChanged(newDate, key) {
    const state = cloneextend.clone(this.state);
    const reminders = cloneextend.clone(this.props.reminders);
    reminders.reminders[key] = this.replaceDateReminders(
      reminders.reminders[key],
      newDate
    );
    this.props.saveSpecificationData(state, reminders);
  }

  fileToBdpChanged(newDate) {
    this.dateChanged(newDate, "fileToBdp");
    const state = cloneextend.clone(this.state);
    state.fileToBdp = newDate.format("YYYY-MM-DD");
    this.props.saveSpecificationData(state);
    this.setState({ fileToBdp: newDate.format("YYYY-MM-DD") });
  }

  fileToPrintChanged(newDate) {
    this.dateChanged(newDate, "fileToPrint");
    const state = cloneextend.clone(this.state);
    state.fileToPrint = newDate.format("YYYY-MM-DD");
    this.props.saveSpecificationData(state);
    this.setState({ fileToPrint: newDate.format("YYYY-MM-DD") });
  }

  printToMailHouseChanged(newDate) {
    this.dateChanged(newDate, "printToMailHouse");
    const state = cloneextend.clone(this.state);
    state.printToMailHouse = newDate.format("YYYY-MM-DD");
    this.props.saveSpecificationData(state);
    this.setState({ printToMailHouse: newDate.format("YYYY-MM-DD") });
  }

  remindersChanged(key, newReminders) {
    const state = cloneextend.clone(this.state);
    const remindersContainer = cloneextend.clone(this.props.reminders);
    remindersContainer.reminders[key] = newReminders;
    this.props.saveSpecificationData(state, remindersContainer);
  }

  proofsChanged(proofs) {
    const state = { ...this.state };
    state.proofs = proofs;
    this.props.saveSpecificationData(state);
    this.setState({ proofs: proofs });
  }

  deleteClicked() {
    this.props.deleteSpecification();
  }

  saveExtraFields(fields) {
    this.setState({ extraFields: fields });
    const state = cloneextend.clone(this.state);
    state.extraFields = fields;
    this.props.saveSpecificationData(state);
  }

  createOrder(type) {
    // !Important. The file creation interferes with Hot reloading
    // resulting in the page reloading.
    // To prevent this in chrome. Open devtools, select 'More Tools'
    // from the drop down.
    // Select 'REquest blocking'
    // Enter 'localhost:3000/sockjs-node/*'
    this.props.saveForm();
    post(
      "/admin/procurement/createOrder",
      {
        type: type,
        title: this.state.title,
        bermudaJobNumber: this.state.bermudaJobNumber,
        grjJobNoPrint: this.state.grjJobNoPrint,
        grjJobNoPaper: this.state.grjJobNoPaper,
        grjJobNoProof: this.state.grjJobNoProof,
        grjPoNumber: this.state.grjPoNumber,
        designer: this.state.designer,
        quantity: this.state.quantity,
        versions: this.state.versions,
        pageSize: this.state.pageSize,
        textPages: this.state.textPages,
        coverPages: this.state.coverPages,
        noOfColours: this.state.noOfColours,
        binding: this.state.binding,
        spineWidth: this.state.spineWidth,
        weight: this.state.weight,
        deliveryNote: this.state.deliveryNote,
        quotePaper: this.state.quotePaper,
        quotePrint: this.state.quotePrint,
        quoteTotal: this.state.quoteTotal,
        printer: this.state.printer,
        fileToBdp: this.state.fileToBdp,
        fileToPrint: this.state.fileToPrint,
        printToMailHouse: this.state.printToMailHouse,
        signOffTime: this.state.signOffTime,
        signOffComment: this.state.signOffComment,
        signOffDate: this.state.signOffDate,
        extraFields: this.state.extraFields,
        proofs: this.state.proofs,
        invoiceNumber: this.state.invoiceNumber
      },
      response => {
        this.setState({ errors: {} });
        if (type === "confirmation") {
          this.setState({ confirmationFile: response.fileTitle });
        } else {
          this.setState({ orderFile: response.fileTitle });
        }
      },
      errors => {
        this.setState({ notFound: true });
      }
    );
  }

  createPaperInvoiceClicked(event) {
    this.setState({ makingInvoice: true });
    event.preventDefault();
    this.props.saveForm(() => {
      post(
        "/admin/invoice/create/",
        {
          campaignId: this.props.campaignId,
          invoiceNumber: this.state.invoiceNumber,
          type: "paper"
        },
        () => {
          setTimeout(() => {
            this.setState({ makingInvoice: false });
            this.setState({
              invoiceLink: `/invoices-udyrqwbfgizdu/INV-${this.state.invoiceNumber}.docx`
            });
          }, 1000);
        }
      );
    });
  }

  createPrintInvoiceClicked(event) {
    this.setState({ makingInvoice: true });
    event.preventDefault();
    this.props.saveForm(() => {
      post(
        "/admin/invoice/create/",
        {
          campaignId: this.props.campaignId,
          invoiceNumber: this.state.invoiceNumber,
          type: "print"
        },
        () => {
          setTimeout(() => {
            this.setState({ makingInvoice: false });
            this.setState({
              invoiceLinkProof: `/invoices-udyrqwbfgizdu/INV-${this.state.invoiceNumber}a.docx`
            });
          }, 1000);
        }
      );
    });
  }

  render() {
    const readOnly = this.props.formType === "edit" || "create" ? false : true;

    let fileToBdpReminders = null;
    let fileToPrintReminders = null;
    let printToMailHouseReminders = null;
    let signOffDateReminders = null;
    let reminders = null;
    if (this.props.reminders) {
      reminders = this.props.reminders.reminders;
      fileToBdpReminders = this.props.reminders.reminders.fileToBdp;
      fileToPrintReminders = this.props.reminders.reminders.fileToPrint;
      printToMailHouseReminders = this.props.reminders.reminders
        .printToMailHouse;
      signOffDateReminders = this.props.reminders.reminders.signOffDate;
    }

    let autocompleteData;
    if (this.props.autocompleteData.specification) {
      autocompleteData = this.props.autocompleteData.specification;
    }

    return (
      <div className="Specification">
        <div className="Form_section">
          <table cellPadding="0" cellSpacing="0" className="Form_table">
            <tbody>
              <tr>
                <td>Title</td>
                <td colSpan="2">
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.title : []}
                    value={this.state.title || ""}
                    onChange={this.titleChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputLarge"
                  />
                  <span className="Form_printInput">{this.state.title}</span>
                </td>

                <td>
                  {this.props.formType === "edit" ? (
                    <button onClick={this.deleteClicked.bind(this)}>
                      Delete
                    </button>
                  ) : (
                    ""
                  )}
                </td>
              </tr>

              <tr>
                <td>Bermuda Job No.</td>
                <td colSpan="3">
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.bermudaJobNumber : []
                    }
                    value={this.state.bermudaJobNumber || ""}
                    onChange={this.bermudaJobNumberChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.bermudaJobNumber}
                  </span>
                </td>
              </tr>

              <tr>
                <td>GRJ Job No. Print</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.grjJobNoPrint : []
                    }
                    value={this.state.grjJobNoPrint || ""}
                    onChange={this.grjJobNoPrintChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.grjJobNoPrint}
                  </span>
                </td>
                <td>GRJ PO number</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.grjPoNumber : []}
                    value={this.state.grjPoNumber || ""}
                    onChange={this.grjPoNumberChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.grjPoNumber}
                  </span>
                </td>
              </tr>

              <tr>
                <td className="smallText">GRJ Job No. Paper</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.grjJobNoPaper : []
                    }
                    value={this.state.grjJobNoPaper || ""}
                    onChange={this.grjJobNoPaperChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.grjJobNoPaper}
                  </span>
                </td>
                <td>GRJ Job No. Proof</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.grjJobNoProof : []
                    }
                    value={this.state.grjJobNoProof || ""}
                    onChange={this.grjJobNoProofChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.grjJobNoProof}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Designer</td>
                <td colSpan="3">
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.designer : []}
                    value={this.state.designer || ""}
                    onChange={this.designerChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.designer}</span>
                </td>
              </tr>

              <tr>
                <td>Quantity</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.quantity : []}
                    value={this.state.quantity || ""}
                    onChange={this.quantityChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.quantity}</span>
                </td>
                <td>Printer</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.printer : []}
                    value={this.state.printer || ""}
                    onChange={this.printerChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.printer}</span>
                </td>
              </tr>

              <tr>
                <td>Versions</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.versions : []}
                    value={this.state.versions || ""}
                    onChange={this.versionsChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.versions}</span>
                </td>
                <td>File to BDP</td>
                <td>
                  <DateField
                    formType={this.props.formType}
                    showMessage
                    fieldName={"File To BDP"}
                    campaignId={this.props.campaignId}
                    campaignName={this.props.campaignName}
                    specificationName={this.state.title}
                    reminders={fileToBdpReminders}
                    dateValue={this.state.fileToBdp}
                    onDateChanged={this.fileToBdpChanged.bind(this)}
                    remindersChanged={this.remindersChanged.bind(
                      this,
                      "fileToBdp"
                    )}
                  />
                  <span className="Form_printInput">
                    {this.state.fileToBdp}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Page Size</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.pageSize : []}
                    value={this.state.pageSize || ""}
                    onChange={this.pageSizeChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.pageSize}</span>
                </td>

                <td>Sign Off Date</td>
                <td>
                  <DateField
                    formType={this.props.formType}
                    showMessage
                    fieldName={"Sign Off Date"}
                    campaignId={this.props.campaignId}
                    campaignName={this.props.campaignName}
                    specificationName={this.state.title}
                    reminders={signOffDateReminders}
                    dateValue={this.state.signOffDate}
                    onDateChanged={this.signOffDateChanged.bind(this)}
                    remindersChanged={this.remindersChanged.bind(
                      this,
                      "signOffDate"
                    )}
                  />
                  <span className="Form_printInput">
                    {this.state.signOffDate}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Text Pages</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.textPages : []}
                    value={this.state.textPages || ""}
                    onChange={this.textPagesChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.textPages}
                  </span>
                </td>

                <td>Sign Off Time</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.signOffTime : []}
                    value={this.state.signOffTime || ""}
                    onChange={this.signOffTimeChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.signOffTime}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Cover Pages</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.coverPages : []}
                    value={this.state.coverPages || ""}
                    onChange={this.coverPagesChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.coverPages}
                  </span>
                </td>

                <td>Sign Off Comment</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.signOffComment : []
                    }
                    value={this.state.signOffComment || ""}
                    onChange={this.signOffCommentChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputMedium"
                  />
                  <span className="Form_printInput">
                    {this.state.signOffComment}
                  </span>
                </td>
              </tr>

              <tr>
                <td>No. Of Colours</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.noOfColours : []}
                    value={this.state.noOfColours || ""}
                    onChange={this.noOfColoursChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.noOfColours}
                  </span>
                </td>

                {this.props.userRole === "admin" ? (
                  <td>File to Print&nbsp;</td>
                ) : (
                  <td>&nbsp;</td>
                )}

                {this.props.userRole === "admin" ? (
                  <td>
                    <DateField
                      formType={this.props.formType}
                      showMessage
                      fieldName={"File To Print"}
                      campaignName={this.props.campaignName}
                      specificationName={this.state.title}
                      reminders={fileToPrintReminders}
                      dateValue={this.state.fileToPrint}
                      onDateChanged={this.fileToPrintChanged.bind(this)}
                      remindersChanged={this.remindersChanged.bind(
                        this,
                        "fileToPrint"
                      )}
                    />
                    <span className="Form_printInput">
                      {this.state.fileToPrint}
                    </span>
                  </td>
                ) : (
                  <td>&nbsp;</td>
                )}
              </tr>

              <tr>
                <td>Binding</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.binding : []}
                    value={this.state.binding || ""}
                    onChange={this.bindingChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.binding}</span>
                </td>

                <td>Delivery to Mail House&nbsp; </td>
                <td className="Form_dateParent">
                  <DateField
                    formType={this.props.formType}
                    showMessage
                    fieldName={"Delivery to mail house"}
                    campaignName={this.props.campaignName}
                    specificationName={this.state.title}
                    reminders={printToMailHouseReminders}
                    dateValue={this.state.printToMailHouse}
                    onDateChanged={this.printToMailHouseChanged.bind(this)}
                    remindersChanged={this.remindersChanged.bind(
                      this,
                      "printToMailHouseChanged"
                    )}
                  />
                  <span className="Form_printInput">
                    {this.state.printToMailHouse}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Spine Width</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.spineWidth : []}
                    value={this.state.spineWidth || ""}
                    onChange={this.spineWidthChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.spineWidth}
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Weight</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.weight : []}
                    value={this.state.weight || ""}
                    onChange={this.weightChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.weight}</span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Delivery Note</td>
                <td colSpan="3">
                  <textarea
                    className="Specification_deliveryNote"
                    value={this.state.deliveryNote || ""}
                    ref="deliveryNote"
                    readOnly={readOnly}
                    onChange={this.deliveryNoteChanged.bind(this)}
                    type="text"
                    rows="3"
                  />
                  <span className="Form_printInput">
                    {this.state.deliveryNote}
                  </span>
                </td>
              </tr>

              <tr>
                <td>Quote Paper</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.quotePaper : []}
                    value={this.state.quotePaper || ""}
                    onChange={this.quotePaperChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.quotePaper}
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Quote Print</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.quotePrint : []}
                    value={this.state.quotePrint || ""}
                    onChange={this.quotePrintChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.quotePrint}
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Total</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.quoteTotal : []}
                    value={this.state.quoteTotal || ""}
                    onChange={this.quoteTotalChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.quoteTotal}
                  </span>
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>Proofs</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.proofs : []}
                    value={this.state.proofs || ""}
                    onChange={this.proofsChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.proofs}</span>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <ExtraFields
            formType={this.props.formType}
            fields={this.state.extraFields}
            remindersChanged={this.remindersChanged.bind(this)}
            reminders={reminders}
            specificationId={this.state.id}
            specificationName={this.state.title}
            saveExtraFields={this.saveExtraFields.bind(this)}
          />

          {this.props.userRole === "admin" ? (
            <div className="Specification_buttons">
              <div>
                <button onClick={this.createOrder.bind(this, "confirmation")}>
                  Save and Create Order Confirmation
                </button>
                {this.state.confirmationFile ? (
                  <a
                    href={
                      clientConfig.apiUrl +
                      "/files/" +
                      this.state.confirmationFile +
                      ".docx"
                    }
                    download
                  >
                    Download Word Doc
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div>
                <button onClick={this.createOrder.bind(this, "purchase")}>
                  Save and Create Purchase Order
                </button>

                {this.state.orderFile ? (
                  <a
                    href={
                      clientConfig.apiUrl +
                      "/files/" +
                      this.state.orderFile +
                      ".docx"
                    }
                    download
                  >
                    Download Word Doc
                  </a>
                ) : (
                  ""
                )}
              </div>

              {this.props.formType !== "create" ? (
                <div className="Form_buttons2">
                  {this.state.makingInvoice ? <span>Please Wait</span> : ""}
                  {this.state.invoiceLink.length > 0 ? (
                    <a href={this.state.invoiceLink} download>
                      Download Invoice
                    </a>
                  ) : (
                    ""
                  )}
                  {this.state.invoiceLinkProof.length > 0 ? (
                    <a href={this.state.invoiceLinkProof} download>
                      Download Proof Invoice
                    </a>
                  ) : (
                    ""
                  )}

                  {this.props.userRole === "admin" ? (
                    <button onClick={this.createPaperInvoiceClicked.bind(this)}>
                      Create Paper Invoice
                    </button>
                  ) : (
                    ""
                  )}
                  {this.props.userRole === "admin" ? (
                    <button onClick={this.createPrintInvoiceClicked.bind(this)}>
                      Create Print Invoice
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
