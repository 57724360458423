import React, { Component } from 'react';
import './App.css';
import { post } from './helpers/api';
// import { Router, Route } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';

import TopNav from './components/TopNav';
import Home from './pages/Home/Home';
import Procurement from './pages/Procurement/Procurement';
// import NotFound from './pages/NotFound/NotFound';

export default class App extends Component {

  state = {
    page: 'home',
    userRole: '',
    loaded: false
  };

  componentWillMount() {
    post('/session/fetchUserRole', {},
      (response) => {
        this.setState({
          userRole: response.userRole,
          loaded: true,
        });
      });
  }

  setUserRole(role) {
    this.setState({userRole: role});
  }

  render() {

    if (!this.state.loaded) {
      return (<div />);
    }

    return (
      <div className="App">
        <link rel="stylesheet" href="/font-awsome/css/font-awesome.min.css" />
        <BrowserRouter>
          <div>
            <Route
              path="/"
              render={(props) =>
                <TopNav
                  {...props}
                  userRole={this.state.userRole} />
              }/>

            <Route
              path="/"
              exact={true}
              render={(props) =>
                <Home
                  {...props}
                  userRole={this.state.userRole}
                  setUserRole={this.setUserRole.bind(this)} />
              }/>

            {this.state.userRole ? (
              <Route
                path="/procurement"
                render={(props) =>
                  <Procurement
                    {...props}
                    userRole={this.state.userRole} />
                }/>
            ) : ''}


          </div>
        </BrowserRouter>
      </div>
    );
  }
}
