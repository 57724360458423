import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactAutocomplete from 'react-autocomplete';
import './AutoComplete.css';

export default class AutoComplete extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    // data should be in format:
    // [
    //  { label: 'foo' },
    //  ...
    // ]
    data: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired, // eg Form_inputLarge
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    value: '',
  };

  componentWillMount() {
    this.setState({ value: this.props.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  changed(title, event) {
    const newTitle = title || event.target.value;
    this.setState({ title: newTitle });
    this.props.onChange(newTitle);
  }


  shouldItemRender(item, value) {
    // If the item is found in a list item
    return item.label.toLowerCase().indexOf(value.toLowerCase()) > -1
      && item.label !== value;
  }

  render() {
    return (
      <div className={this.props.className + " AutoComplete"}>
        <ReactAutocomplete
          getItemValue={(item) => item.label}
          items={this.props.data}
          renderItem={(item, isHighlighted) =>
            <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
              {item.label}
            </div>
          }
          shouldItemRender={this.shouldItemRender.bind(this)}
          menuStyle={{
            borderRadius: '3px',
            boxShadow: 'rgba(0, 0, 0, 0.5) 3px 5px 15px',
            background: 'rgba(255, 255, 255, 0.9)',
            padding: '0',
            fontSize: '90%',
            overflow: 'auto',
            maxHeight: '500px',
            position: 'absolute',
            top: '17px',
            left: 0,
            zIndex: 100,
          }}
          wrapperStyle={{ position: 'relative' }}
          value={this.state.value}
          onChange={this.changed.bind(this, null)}
          onSelect={(value) => this.changed(value)}
          readOnly={this.props.readOnly} />

      </div>
    );
  }
}
