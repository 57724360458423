import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Email.css';
import EmailPanel from '../EmailPanel/EmailPanel';
import { post } from '../../../../helpers/api';

export default class Email extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
  };

  state = {
    selectedEmails: [],
    messageSent: false,
    sendingMessage: false,
    sendingError: false,
    message: '',
  };

  onSelectedEmailsChanged(emails) {
    this.setState({selectedEmails: emails});
  }

  sendEmail() {
    this.setState({sendingMessage: true});
    post('/admin/procurement/sendemail', {
        emails: this.state.selectedEmails,
        message: this.state.message,
        campaignId: this.props.campaignId,
      },
      (response) => {
        this.setState({
          messageSent: true,
          sendingMessage: false,
          sendingError: false,
        });
      },
      (errors) => {
        this.setState({
          sendingError: true,
          messageSent: false,
          sendingMessage: false,
        });
      });
  }

  messageChanged() {
    this.setState({ message: this.refs.message.value});
  }

  render() {

    return (
      <div className="Email">
        <h2 className="Form_sectionTitle">Email</h2>
        <div className="Form_section Email_form">
          <div className="Email_title">
            Message
          </div>
          <textarea
            value={this.state.message}
            ref="message"
            onChange={this.messageChanged.bind(this)}
            className="Email_message"
            rows="5" />
          <EmailPanel
            onSelectedEmailsChanged={this.onSelectedEmailsChanged.bind(this)} />
          <div>
            <button
              onClick={this.sendEmail.bind(this)}
              className="Email_send" >
              Send Email
            </button>
          </div>

          {this.state.sendingMessage ? (
            <div>
              <strong>Please wait...</strong>
            </div>
          ) : ''}

          {this.state.sendingError ? (
            <div className="error">
              There was an error when trying to send this message.
            </div>
          ) : ''}

          {this.state.messageSent ? (
            <div>
              This message has been sent
            </div>
          ) : ''}
        </div>
      </div>
    );
  }
}
