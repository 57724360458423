import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Campaign.css";
import DateField from "../DateField/DateField";
import AutoComplete from "../AutoComplete/AutoComplete";

export default class Campaign extends Component {
  static propTypes = {
    autocompleteData: PropTypes.object.isRequired,
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    saveCampaignData: PropTypes.func.isRequired,
    campaignData: PropTypes.object,
    campaignId: PropTypes.number,
    errors: PropTypes.object
  };

  state = {
    title: "",
    clientContact: "",
    brand: "GRJ",
    mailDate: "",
    doorstepDate: "",
    paperCutOffDate: "",
    dataCutOffDate: ""
  };

  mailDate = null; // reserved for the mailDate DatePicker
  doorstepDate = null; // reserved for the doorstepDate DatePicker
  paperCutOffDate = null; // reservd for the paperCutOffDate DatePicker
  dataCutOffDate = null; // reservd for the dataCutOffDate DatePicker

  componentWillMount() {
    this.setCampaignState(this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props.campaignId !== nextProps.campaignId) {
      this.setCampaignState(nextProps);
    }
  }

  setCampaignState(props) {
    if (props.campaignId !== null) {
      this.setState({
        title: props.campaignData.title,
        clientContact: props.campaignData.clientContact,
        brand: props.campaignData.brand,
        mailDate: props.campaignData.mailDate,
        doorstepDate: props.campaignData.doorstepDate,
        paperCutOffDate: props.campaignData.paperCutOffDate,
        dataCutOffDate: props.campaignData.dataCutOffDate
      });
    }
  }

  componentDidMount() {
    if (!this.props.campaignData.brand) {
      this.brandChanged();
    }
  }

  titleChanged(title) {
    const state = { ...this.state };
    state.title = title;
    this.props.saveCampaignData(state);
    this.setState({ title: title });
  }

  brandChanged() {
    const state = { ...this.state };
    state.brand = this.refs.brand.value;
    this.props.saveCampaignData(state);
    this.setState({ brand: this.refs.brand.value });
  }

  clientContactChanged(clientContact) {
    const state = { ...this.state };
    state.clientContact = clientContact;
    this.props.saveCampaignData(state);
    this.setState({ clientContact: clientContact });
  }

  mailDateChanged(newDate) {
    const state = { ...this.state };
    state.mailDate = newDate.format("YYYY-MM-DD");
    this.props.saveCampaignData(state);
    this.setState({ mailDate: newDate.format("YYYY-MM-DD") });
  }

  doorstepDateChanged(newDate) {
    const state = { ...this.state };
    state.doorstepDate = newDate.format("YYYY-MM-DD");
    this.props.saveCampaignData(state);
    this.setState({ doorstepDate: newDate.format("YYYY-MM-DD") });
  }

  paperCutOffDateChanged(newDate) {
    const state = { ...this.state };
    state.paperCutOffDate = newDate.format("YYYY-MM-DD");
    this.props.saveCampaignData(state);
    this.setState({ paperCutOffDate: newDate.format("YYYY-MM-DD") });
  }

  dataCutOffDateChanged(newDate) {
    const state = { ...this.state };
    state.dataCutOffDate = newDate.format("YYYY-MM-DD");
    this.props.saveCampaignData(state);
    this.setState({ dataCutOffDate: newDate.format("YYYY-MM-DD") });
  }

  dateGraphicClicked() {
    this.mailDate.setOpen(true);
  }

  paperCutOffDateGraphicClicked() {
    this.paperCutOffDate.setOpen(true);
  }

  dataCutOffDateGraphicClicked() {
    this.dataCutOffDate.setOpen(true);
  }

  render() {
    const readOnly = this.props.formType === "edit" || "create" ? false : true;
    let autocompleteData;
    if (this.props.autocompleteData.campaign) {
      autocompleteData = this.props.autocompleteData.campaign;
    }

    return (
      <div className="Campaign">
        <h2 className="Form_sectionTitle">Campaign</h2>
        <div className="Form_section">
          <table className="Form_table" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td>Campaign Title</td>
                <td>
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.title : []}
                    value={this.state.title || ""}
                    onChange={this.titleChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputMedium"
                  />
                  <span className="Form_printInput">{this.state.title}</span>
                  <div className="Form_error">{this.props.errors.title}</div>
                </td>
                <td>Brand</td>
                <td>
                  <select
                    className="Campaign_select"
                    value={this.state.brand || ""}
                    ref="brand"
                    disabled={readOnly}
                    onChange={this.brandChanged.bind(this)}
                  >
                    <option value="GRJ">GRJ</option>
                    <option value="RD">RD</option>
                    <option value="GCJ">GCJ</option>
                    <option value="USA">USA</option>
                  </select>
                  <span className="Form_printInput">{this.state.brand}</span>
                </td>
              </tr>
              <tr>
                <td>Client Contact</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.clientContact : []
                    }
                    value={this.state.clientContact || ""}
                    onChange={this.clientContactChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputMedium"
                  />
                  <span className="Form_printInput">
                    {this.state.clientContact}
                  </span>
                </td>
                <td>Mail Date </td>
                <td>
                  <DateField
                    formType={this.props.formType}
                    dateValue={this.state.mailDate}
                    showMessage={false}
                    onDateChanged={this.mailDateChanged.bind(this)}
                  />
                  <span className="Form_printInput">{this.state.mailDate}</span>
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="Campaign_date">
                  Paper / Pagination cut off date
                  <DateField
                    formType={this.props.formType}
                    dateValue={this.state.paperCutOffDate}
                    showMessage={false}
                    onDateChanged={this.paperCutOffDateChanged.bind(this)}
                  />
                  <span className="Form_printInput">
                    &nbsp;&nbsp;&nbsp;
                    {this.state.paperCutOffDate}
                  </span>
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="Campaign_date">
                  Data cut off date
                  <DateField
                    formType={this.props.formType}
                    dateValue={this.state.dataCutOffDate}
                    showMessage={false}
                    onDateChanged={this.dataCutOffDateChanged.bind(this)}
                  />
                  <span className="Form_printInput">
                    &nbsp;&nbsp;&nbsp;
                    {this.state.dataCutOffDate}
                  </span>
                </td>
                <td colSpan="2" className="Campaign_date">
                  Doorstep Date
                  <DateField
                    formType={this.props.formType}
                    dateValue={this.state.doorstepDate}
                    showMessage={false}
                    onDateChanged={this.doorstepDateChanged.bind(this)}
                  />
                  <span className="Form_printInput">
                    &nbsp;&nbsp;&nbsp;
                    {this.state.doorstepDate}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
