import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ExtraFields.css';
import DateField from '../../DateField/DateField';
import cloneextend from 'cloneextend';
import clientConfig from '../../../../../clientConfig';

import Add from './Add/Add';

export default class ExtraFields extends Component {
  static propTypes = {
    formType: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    specificationId: PropTypes.number.isRequired,
    specificationName: PropTypes.string,
    reminders: PropTypes.object,
    remindersChanged: PropTypes.func,
    saveExtraFields: PropTypes.func,
  };

  state = {
    fields: [],
  };

  componentWillMount() {
    this.setState({fields: this.props.fields});
  }

  saveExtraFields(fields) {
    this.setState({fields});
    this.props.saveExtraFields(fields);
  }

  remindersChanged(fieldTitle, reminders) {
    this.props.remindersChanged(fieldTitle, reminders);
  }

  textFieldChanged(index) {
    const fields = [ ...this.state.fields ];
    fields[index].value = this.refs['textField' + index].value;
    this.setState({fields});
    this.props.saveExtraFields(fields);
  }

  dateChanged(index, newDate) {
    const fields = [ ...this.state.fields ];
    fields[index].value = newDate.format('YYYY-MM-DD');
    this.setState({fields});
    this.props.saveExtraFields(fields);

    const reminders = cloneextend.clone(
      this.props.reminders[fields[index].fieldTitle]
    );
    reminders.date1.dateToSend = newDate
      .clone()
      .add(clientConfig.reminders.date1, 'days')
      .format('YYYY-MM-DD');
    reminders.date2.dateToSend = newDate
      .clone()
      .add(clientConfig.reminders.date2, 'days')
      .format('YYYY-MM-DD');
    reminders.date3.dateToSend = newDate
      .clone()
      .add(clientConfig.reminders.date3, 'days')
      .format('YYYY-MM-DD');
    this.props.remindersChanged(
      fields[index].fieldTitle,
      reminders
    );
  }

  render() {
    return (
      <div className="ExtraFields">

        <div className="ExtraFields_rows">
          {this.state.fields.map((field, index) => {

            let reminders = {};
            if (this.props.reminders) {
              reminders = this.props.reminders[field.fieldTitle];
            }

            return (
              <div key={index}>
                <label>{field.fieldTitle}</label>
                <span className="Form_printInput">{field.value}</span>

                {field.fieldType === 'Text' ? (
                  <input
                    type="text"
                    value={field.value}
                    ref={'textField' + index}
                    onChange={this.textFieldChanged.bind(this, index)}
                    className="Form_inputLarge" />
                ) : ''}

                {field.fieldType === 'Date' ? (
                  <DateField
                    formType={this.props.formType}
                    dateValue={field.value}
                    showMessage={false}
                    onDateChanged={this.dateChanged.bind(this, index)} />
                ) : ''}

                {field.fieldType === 'DateWithReminder' ? (
                  <DateField
                    formType={this.props.formType}
                    showMessage
                    fieldName={field.fieldTitle}
                    campaignId={this.props.campaignId}
                    campaignName={this.props.campaignName}
                    specificationName={this.state.title}
                    dateValue={field.value}
                    onDateChanged={this.dateChanged.bind(this, index)}
                    reminders={reminders}
                    remindersChanged={this.remindersChanged.bind(this, field.fieldTitle)} />
                ) : ''}

              </div>
            );
          })}
        </div>

        {this.props.formType === 'edit' || this.props.formType === 'create' ? (
          <Add
            reminders={this.props.reminders}
            remindersChanged={this.remindersChanged.bind(this)}
            saveExtraFields={this.saveExtraFields.bind(this)}
            fields={this.state.fields} />
        ) : ''}
      </div>
    );
  }
}
