import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './TopNav.css';

// import fetch from 'node-fetch';

export default class TopNav extends Component {
  static propTypes = {
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
  };

  logout(event) {
    event.preventDefault();
    document.cookie = 'sessionId=0;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/'
    document.cookie = '';
    window.location.href = '/';
  }

  render() {
    const pathParts = this.props.location.pathname.split('/');
    if (pathParts[2] === 'jobsheet') {
      return <div />;
    }

    return (
      <div id="TopNav" >
        <img className="TopNav_logo" alt="Logo" src="/images/header.jpg" />
        <img className="TopNav_printlogo" alt="Logo" src="/images/grjlogo.jpg" />
        <div className="TopNav_nav">
          <Link className="TopNav_firstLink" to="/">Home</Link>
          {this.props.userRole === 'admin' ? (
            <Link to="/procurement/create">Create New Campaign</Link>
          ) : ''}
          {this.props.userRole !== '' ? (
            <div className="TopNav_userLinks">
              <Link to="/procurement/list/all">View All Campaigns</Link>

              <Link to="/procurement/list/grj">View GRJ Campaigns</Link>

              <Link to="/procurement/list/rd">View RD Campaigns</Link>

              <Link to="/procurement/list/gcj">View GCJ Campaigns</Link>

              <Link to="/procurement/list/usa">View USA Campaigns</Link>

              <a href="" onClick={this.logout.bind(this)}>Log Out</a>
            </div>
          ) : ''}
        </div>
      </div>
    );
  }
}
