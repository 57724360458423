import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Notes.css';

export default class Notes extends Component {
  static propTypes = {
    campaignId: PropTypes.number,
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    notesData: PropTypes.object,
    saveNotesData: PropTypes.func.isRequired,
  };

  state = {
    note: '',
  };

  componentWillMount() {
    this.setInitialState(this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props.campaignId !== nextProps.campaignId) {
      this.setInitialState(nextProps);
    }
  }

  setInitialState(props) {
    if (props.campaignId !== null) {
      this.setState({
        note: props.notesData.note,
      });
    }
  }

  noteChanged() {
    const state = { ...this.state };
    state.note = this.refs.note.value;
    this.props.saveNotesData(state);
    this.setState({ note: this.refs.note.value });
  }

  render() {

    const readOnly = this.props.formType === 'edit' || 'create' ? false : true;

    return (
      <div className="Notes">
        <h2 className="Form_sectionTitle">Notes</h2>
        <div className="Form_section">
          <textarea
            className="Notes_textarea"
            value={this.state.note || ''}
            ref="note"
            readOnly={readOnly}
            onChange={this.noteChanged.bind(this)}
            type="text"
            rows="3" />
          <span className="Form_printInput">{this.state.note}</span>
        </div>
      </div>
    );
  }
}
