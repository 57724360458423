import { apiUrl } from "../clientConfig";
import "whatwg-fetch";

const sessionPromise = new Promise((resolve, reject) => {
  let count = 0;
  const timeout = 1000 * 60; // 1 min
  const waiting = () => {
    setTimeout(() => {
      count++;
      const sessionId = getSessionId();
      if (sessionId) {
        resolve();
      } else if (count > timeout) {
        console.error("Session creation is taking too long");
        reject();
      } else {
        waiting();
      }
    }, 100);
  };
  waiting();
});

function getSessionId() {
  const cookieParts = document.cookie.split(";");
  let cookieSessionId;
  cookieParts.forEach(part => {
    const splitPart = part.split("=");
    if (splitPart[0] === "sessionId") cookieSessionId = splitPart[1];
  });
  return cookieSessionId;
}

export function createSession() {
  const cookieSessionId = getSessionId();

  const fetchCookie = () => {
    let response;
    fetch(apiUrl + "/session/create")
      .then(r => {
        response = r;
        return response.json();
      })
      .then(responseData => {
        if (response.status !== 200) {
          console.error("API unable to create session.");
        } else {
          const newSessionId = responseData.sessionId;
          document.cookie = "sessionId=" + newSessionId + "; path=/";
        }
      });
  };

  const checkCookie = () => {
    fetch(apiUrl + "/session/check", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ sessionId: cookieSessionId })
    })
      .then(response => {
        if (response.status !== 200) {
          fetchCookie();
        }
      })
      .then(() => {});
  };

  if (cookieSessionId) {
    checkCookie();
  } else {
    fetchCookie();
  }
}

export function post(url, data, callback, errorCallback) {
  sessionPromise.then(() => {
    let response;
    data.sessionId = getSessionId();
    fetch(apiUrl + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(r => {
        response = r;
        if (response.status === 404) {
          console.error("Error 404 for " + url);
          return;
        }
        return response.json();
      })
      .then(responseData => {
        if (response.status !== 200) {
          if (errorCallback) errorCallback(responseData);
        } else {
          callback(responseData);
        }
      });
  });
}
