import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { BrowserRouter, Route } from 'react-router-dom';
import './Edit.css';

import Form from '../Form/Form';
// import { post } from '../../helpers/api';

export default class Edit extends Component {
  static propTypes = {
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
  };

  render() {

    return (
      <div className="Edit">
        <Form
          formType={this.props.formType}
          location={this.props.location}
          history={this.props.history}
          userRole={this.props.userRole} />
      </div>
    );
  }
}
