import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './Home.css';

import { post } from '../../helpers/api';

export default class Home extends Component {
  static propTypes = {
    userRole: PropTypes.string,
    setUserRole: PropTypes.func.isRequired,
  };

  state = {
    password: '',
    error: '',
  };

  passwordChanged(event) {
    this.setState({
      password: this.refs.password.value,
    });
  }

  passwordDown(event) {
    if (event.charCode  === 13) {
      this.refs.login.click();
    }
  }

  loginClicked() {
    this.setState({error: ''});
    post('/login/loginUser', {
        'password': this.state.password,
      },
      (response) => {
        this.props.setUserRole(response.role);
      },
      (errorData) => {
        this.setState({error: errorData});
      });
  }

  render() {
    return (
      <div className="Home">
        <img
          alt="Train Logo"
          className="Home_train"
          src="/images/train.jpg" />

        {this.props.userRole.length > 0 ? (

          <div className="Home_optionWrapper">
            <Link
              to="/procurement/list/all"
              className="buttonLink Home_login" >
              Procurement
            </Link>
          </div>

        ) : (

          <div className="Home_passwordWrapper">
            <input
              className="Home_password"
              type="password"
              ref="password"
              value={this.state.password}
              onChange={this.passwordChanged.bind(this)}
              onKeyPress={this.passwordDown.bind(this)}
              size="20" />

            <button
              className="button Home_login"
              ref="login"
              onClick={this.loginClicked.bind(this)}>
              Log In
            </button>

            {this.state.error ? (
              <div className="error Home_error">
                {this.state.error}
              </div>
            ) : ''}

          </div>
        )}
      </div>
    );
  }
}
