import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './EmailPanel.css';
import Modal from 'react-modal';
import { post } from '../../../../helpers/api';


export default class EmailPanel extends Component {
  static propTypes = {
    onSelectedEmailsChanged: PropTypes.func.isRequired,
    selectedEmails: PropTypes.array,
  };

  state = {
    showEditOptions: false,
    emails: [],
    newEmail: '',
    newEmailError: null,
    emailToDelete: null,
    selectedEmails: [],
  };

  componentWillMount() {
    this.fetchEmails();
    // this.setState({ selectedEmails: this.props.selectedEmails });
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.selectedEmails !== undefined
      && JSON.stringify(nextProps.selectedEmails) !== JSON.stringify(nextState.selectedEmails)
    ) {
      this.setState({ selectedEmails: nextProps.selectedEmails });
    }
  }

  fetchEmails() {
    if (this.props.formType !== 'create') {
      post('/admin/email/fetchAll', {},
        (response) => {
          this.setState({emails: response});
        },
        (errors) => {
          console.error('Emails not loading');
        });
    }
  }

  editEmailAddressesClicked() {
    this.setState({
      showEditOptions: true,
      newEmailError: null,
      newEmail: '',
    });
  }


  closeEditEmailAddresses() {
    this.setState({showEditOptions: false});
  }

  addNewEmailAddressClicked() {
    post('/admin/email/add', {
        email: this.refs.newEmail.value,
      },
      (response) => {
        this.fetchEmails();
        this.setState({
          newEmailError: '',
          newEmail: '',
        });
      },
      (errors) => {
        this.setState({newEmailError: errors.email});
      });
  }

  newEmailChanged() {
    this.setState({ newEmail: this.refs.newEmail.value });
  }

  deleteEmailAddress(email) {
    this.setState({emailToDelete: email});
  }


  cancelDeleteEmailAddress() {
    this.setState({emailToDelete: null});
  }

  confirmDeleteEmailAddress() {
    post('/admin/email/deleteEmailAddress', {
        email: this.state.emailToDelete,
      },
      (response) => {
        this.fetchEmails();

        const selectedEmails = [ ...this.state.selectedEmails ];
        const selectedIndex = this.state.selectedEmails.indexOf(this.state.emailToDelete);
        if (selectedIndex >= 0) {
          selectedEmails.splice(selectedIndex, 1);
        }
        this.setState({ selectedEmails });
        this.props.onSelectedEmailsChanged(selectedEmails);

        this.setState({emailToDelete: null});
      },
      (errors) => {
        console.error('Error when deleting email address ' + this.state.emailToDelete);
      });
  }

  onEmailSelected(email) {
    const selectedEmails = [ ...this.state.selectedEmails ];
    const selectedIndex = this.state.selectedEmails.indexOf(email);
    if (selectedIndex >= 0) {
      selectedEmails.splice(selectedIndex, 1);
    } else {
      selectedEmails.push(email);
    }
    this.setState({ selectedEmails });
    this.props.onSelectedEmailsChanged(selectedEmails);
  }

  render() {
    return (
      <div className="EmailPanel">
        <Modal
          isOpen={this.state.emailToDelete !== null}
          onAfterOpen={()=>{}}
          onRequestClose={()=>{}}
          className={{
            base: 'confirmModalBase',
          }}
          overlayClassName={{
            base: 'confirmModalBaseOverlay',
          }}
          contentLabel="DeleteConfirmModal">
          <div>
            Please confirm deletion of Email address&nbsp;
            <strong>{this.state.emailToDelete}</strong>
          </div>

          <div className="EmailPanel_modalFooter">
            <button
              className="EmailPanel_closeButton"
              onClick={this.cancelDeleteEmailAddress.bind(this)}>
              Cancel
            </button>
            <button
              className="EmailPanel_closeButton"
              onClick={this.confirmDeleteEmailAddress.bind(this)}>
              Confirm
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.showEditOptions}
          onAfterOpen={()=>{}}
          onRequestClose={()=>{}}
          className={{
            base: 'emailModalBase',
          }}
          overlayClassName={{
            base: 'emailModalBaseOverlay',
          }}
          contentLabel="Modal">
          <h2>Edit Email Addresses</h2>

          <ul className="EmailPanel_deleteList">
            {this.state.emails.map((email, index) => {
              return (
                <li key={index}>
                  <button
                    onClick={this.deleteEmailAddress.bind(this, email.email)}>
                    Delete
                  </button>
                  {email.email}
                </li>
              );
            })}
          </ul>

          <div className="EmailPanel_newEmailAddress">
            <input
              type="text"
              ref="newEmail"
              value={this.state.newEmail}
              onChange={this.newEmailChanged.bind(this)} />
            <button onClick={this.addNewEmailAddressClicked.bind(this)}>
              Add New Email Address
            </button>
            <div className="error">
              {this.state.newEmailError}
            </div>
          </div>

          <div className="EmailPanel_modalFooter">
            <button
              className="EmailPanel_closeButton"
              onClick={this.closeEditEmailAddresses.bind(this)}>
              Close
            </button>
          </div>
        </Modal>

        <h5>Select the emails to be included in this email</h5>
        <ul className="EmailPanel_list">
          {this.state.emails.map((email, index) => {
            const selectedIndex = this.state.selectedEmails.indexOf(email.email);
            const selected = selectedIndex >= 0 ? true : false;
            return (
              <li key={index}>
                <input
                  type="checkbox"
                  checked={selected}
                  onChange={this.onEmailSelected.bind(this, email.email)} />
                {email.email}
              </li>
            );
          })}
        </ul>
        <div>
          <button
            onClick={this.editEmailAddressesClicked.bind(this)}>
            Edit Email Addresses
          </button>
        </div>
      </div>
    );
  }
}
