import React, { Component } from "react";
import PropTypes from "prop-types";
// import { BrowserRouter, Route } from 'react-router-dom';
import "./JobSheet.css";
import moment from "moment";
import { post } from "../../../helpers/api";

moment.locale("en-GB");

export default class JobSheet extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  state = {
    campaignId: null,
    form: {},
    formLoaded: false,
    errors: null
  };

  componentWillMount() {
    this.fetchCampaigns(this.props);
  }

  componentDidUpdate() {
    if (this.state.formLoaded) {
      window.print();
    }
  }

  fetchCampaigns(props) {
    const pathParts = props.location.pathname.split("/");
    this.setState({
      campaignId: pathParts[3]
    });

    post(
      "/user/procurement/fetch",
      {
        campaignId: pathParts[3]
      },
      response => {
        this.setState({ errors: null });
        this.setState({
          form: response,
          formLoaded: true
        });
      },
      errors => {
        this.setState({ notFound: true });
      }
    );
  }

  render() {
    if (this.state.errors) {
      return (
        <div className="error">
          There was an error loading the campaign data
        </div>
      );
    }

    if (!this.state.formLoaded) {
      return <div>Loading...</div>;
    }

    const form = this.state.form;

    return (
      <div className="JobSheet">
        <style>@page {`{ size: landscape; }`}</style>
        <h2>Campaign</h2>
        <table className="JobSheet_campaign">
          <thead>
            <tr>
              <td>Title</td>
              <td>Brand</td>
              <td>Client Contact</td>
              <td>Mail Date</td>
              <td>Paper / Pagination cut off date</td>
              <td>Data cut off date</td>
              <td>Doorstep Date</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{form.campaign.title}</td>
              <td>{form.campaign.brand}</td>
              <td>{form.campaign.clientContact}</td>
              <td>{moment(form.campaign.mailDate).format("DD-MM-YYYY")}</td>
              <td>
                {moment(form.campaign.paperCutOffDate).format("DD-MM-YYYY")}
              </td>
              <td>
                {moment(form.campaign.dataCutOffDate).format("DD-MM-YYYY")}
              </td>
              <td>{moment(form.campaign.doorstepDate).format("DD-MM-YYYY")}</td>
            </tr>
          </tbody>
        </table>

        <h2>Mail House</h2>
        <table className="JobSheet_mailHouse">
          <thead>
            <tr>
              <td>Mail House</td>
              <td>Contact</td>
              <td>Postage</td>
              <td>Contents</td>
              <td>Pack Weight</td>
              <td>Quantity</td>
              <td>Quote</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{form.mailHouse.mailHouse}</td>
              <td>{form.mailHouse.contact}</td>
              <td>{form.mailHouse.postage}</td>
              <td>{form.mailHouse.contents}</td>
              <td>{form.mailHouse.packWeight}</td>
              <td>{form.mailHouse.quantity}</td>
              <td>{form.mailHouse.quote}</td>
            </tr>
          </tbody>
        </table>

        <h2>Specifications</h2>
        <table className="JobSheet_specifications">
          <thead>
            <tr>
              <td>Title</td>
              <td>Bermuda Job No.</td>
              <td>Designer</td>
              <td>Page Size</td>
              <td>Text Pages</td>
              <td>Cover Pages</td>
              <td>Weight</td>
              <td>Printer</td>
              <td>Spine Width</td>
              <td>File to BDP</td>
              <td>Sign Off Time</td>
              <td>Sign Off Date</td>
              <td>File To Print</td>
              <td>Delivery To Mail House</td>
            </tr>
          </thead>
          <tbody>
            {form.specifications.map((specification, index) => {
              return (
                <tr key={index}>
                  <td>{specification.title}</td>
                  <td>{specification.bermudaJobNumber}</td>
                  <td>{specification.designer}</td>
                  <td>{specification.pageSize}</td>
                  <td>{specification.textPages}</td>
                  <td>{specification.coverPages}</td>
                  <td>{specification.weight}</td>
                  <td>{specification.printer}</td>
                  <td>{specification.spineWidth}</td>
                  <td>
                    {moment(specification.fileToBdp).format("DD-MM-YYYY")}
                  </td>
                  <td>{specification.signOffTime}</td>
                  <td>
                    {moment(specification.signOffDate).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {moment(specification.fileToPrint).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    {moment(specification.printToMailHouse).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                  {specification.extraFields.map((extra, index) => {
                    let content = extra.value;
                    if (
                      extra.fieldType === "Date" ||
                      extra.fieldType === "DateWithReminder"
                    ) {
                      content = moment(extra.value).format("DD-MM-YYYY");
                    }
                    return (
                      <td key={index}>
                        <strong>{extra.fieldTitle}:</strong> {content}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

        <h2>Notes</h2>
        <div>{form.notes.note}</div>

        <h2>SEPs complete</h2>
        <div className="sepsComplete"></div>
      </div>
    );
  }
}
