import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import PropTypes from "prop-types";
import "./Form.css";

import Campaign from "./Campaign/Campaign";
import MailHouse from "./MailHouse/MailHouse";
import Notes from "./Notes/Notes";
import Email from "./Email/Email";
import Specification from "./Specification/Specification";
import NotFound from "../../NotFound/NotFound";
import Archive from "./Archive/Archive";
import cloneextend from "cloneextend";

import { post } from "../../../helpers/api";

export default class Form extends Component {
  static propTypes = {
    userRole: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired
  };

  state = {
    form: {
      campaign: {},
      mailHouse: {},
      specifications: [],
      notes: {},
      id: 0
    },
    formLoaded: false,
    remindersList: [],
    remindersLoaded: false,
    errors: {},
    notFound: false,
    saving: false,
    autocomplete: {},
    archived: false
  };

  validSelections = ["all", "grj", "rd", "gcj", "usa"];

  emptyReminders = {
    fileToBdp: {
      date1: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date2: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date3: {
        emails: [],
        dateToSend: "",
        message: ""
      }
    },
    fileToPrint: {
      date1: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date2: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date3: {
        emails: [],
        dateToSend: "",
        message: ""
      }
    },
    printToMailHouse: {
      date1: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date2: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date3: {
        emails: [],
        dateToSend: "",
        message: ""
      }
    },
    signOffDate: {
      date1: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date2: {
        emails: [],
        dateToSend: "",
        message: ""
      },
      date3: {
        emails: [],
        dateToSend: "",
        message: ""
      }
    }
  };

  componentWillMount() {
    if (this.props.formType === "create") {
      const form = { ...this.state.form };
      form.specifications = [];
      this.setState({ form });
    }

    const pathParts = this.props.location.pathname.split("/");
    const campaignId = pathParts[3];
    this.fetchCampaign(campaignId);
    this.fetchReminders(campaignId);
    this.fetchAutoComplete();
  }

  fetchAutoComplete() {
    post(
      "/admin/procurement/fetchAutocomplete",
      {},
      response => {
        this.setState({
          autocomplete: response
        });
      },
      errors => {
        console.error("autocomplete data not loading");
      }
    );
  }

  fetchCampaign(campaignId) {
    if (this.props.formType !== "create") {
      post(
        "/user/procurement/fetch",
        {
          campaignId: campaignId
        },
        response => {
          this.setState({ errors: {} });
          this.setState({
            form: response,
            formLoaded: true,
            archived: response.campaign.archived ? true : false
          });
        },
        errors => {
          this.setState({ notFound: true });
        }
      );
    }
  }

  fetchReminders(campaignId) {
    if (this.props.formType === "edit") {
      post(
        "/admin/procurement/fetchReminders",
        {
          campaignId: campaignId
        },
        response => {
          this.setState({ errors: {} });
          this.setState({
            remindersList: response,
            remindersLoaded: true
          });
        },
        errors => {
          this.setState({ notFound: true });
        }
      );
    }
  }

  saveCampaignData(data) {
    const form = { ...this.state.form };
    form.campaign = data;
    this.setState({ form });
  }

  saveMailHouseData(data) {
    const form = { ...this.state.form };
    form.mailHouse = data;
    this.setState({ form });
  }

  saveNotesData(data) {
    const form = { ...this.state.form };
    form.notes = data;
    this.setState({ form });
  }

  saveEmailData(data) {
    const form = { ...this.state.form };
    form.email = data;
    this.setState({ form });
  }

  saveSpecificationData(index, data, reminders) {
    const form = cloneextend.clone(this.state.form);
    form.specifications[index] = data;
    this.setState({ form });
    if (reminders) {
      const remindersList = cloneextend.clone(this.state.remindersList);
      remindersList[index] = reminders;
      this.setState({ remindersList });
    }
  }

  addSpecificationClicked() {
    const form = { ...this.state.form };
    const newSpecification = {
      id: Date.now(),
      new: true
    };
    form.specifications.push(newSpecification);

    const remindersList = cloneextend.clone(this.state.remindersList);
    remindersList.push({
      specificationId: newSpecification.id,
      reminders: cloneextend.clone(this.emptyReminders)
    });
    this.setState({
      form,
      remindersList
    });
  }

  deleteSpecification(index) {
    const form = { ...this.state.form };
    form.specifications.splice(index, 1);
    this.setState({ form });
  }

  switchArchivedFlag() {
    this.setState({ archived: !this.state.archived });
  }

  saveClicked(callback) {
    this.setState({ saving: true });
    post(
      "/admin/procurement/save",
      {
        form: this.state.form,
        remindersList: this.state.remindersList
      },
      response => {
        setTimeout(() => {
          this.setState({
            errors: {},
            saving: false
          });
        }, 1000);
        if (typeof callback === "function") {
          callback();
        } else {
          if (this.state.form.id === 0) {
            this.props.history.push("/procurement/edit/" + response.campaignId);
          }
        }
      },
      errors => {
        this.setState({
          errors: errors,
          saving: false
        });
      }
    );
  }

  print() {
    window.print();
  }

  render() {
    if (this.state.notFound) {
      return (
        <BrowserRouter>
          <Route component={NotFound} />
        </BrowserRouter>
      );
    }

    if (
      this.props.formType === "edit" &&
      (this.state.form.id === 0 ||
        this.state.remindersLoaded === false ||
        this.state.formLoaded === false)
    ) {
      return <div>Loading</div>;
    }
    return (
      <div className="Procurement">
        <Campaign
          autocompleteData={this.state.autocomplete}
          formType={this.props.formType}
          campaignData={this.state.form.campaign}
          campaignId={this.state.form.id}
          location={this.props.location}
          userRole={this.props.userRole}
          errors={this.state.errors.campaign || {}}
          saveCampaignData={this.saveCampaignData.bind(this)}
        />

        <MailHouse
          autocompleteData={this.state.autocomplete}
          formType={this.props.formType}
          location={this.props.location}
          mailHouseData={this.state.form.mailHouse}
          campaignId={this.state.form.id}
          userRole={this.props.userRole}
          saveMailHouseData={this.saveMailHouseData.bind(this)}
        />

        <h2 className="Form_sectionTitle">
          Specifications
          {this.props.formType === "edit" ||
          this.props.formType === "create" ? (
            <button
              className="Form_addSpecification"
              onClick={this.addSpecificationClicked.bind(this)}
            >
              Add Specification Box
            </button>
          ) : (
            ""
          )}
        </h2>
        {this.state.form.specifications.length === 0 ? (
          <div>No specifications have been entered.</div>
        ) : (
          ""
        )}
        {this.state.form.specifications.map((specification, index) => {
          const reminders = this.state.remindersList.find(reminder => {
            return specification.id === reminder.specificationId;
          });
          return (
            <div className="Form_specificationWrapper" key={index}>
              <Specification
                autocompleteData={this.state.autocomplete}
                formType={this.props.formType}
                deleteSpecification={this.deleteSpecification.bind(this, index)}
                location={this.props.location}
                userRole={this.props.userRole}
                specification={specification}
                campaignId={this.state.form.id}
                campaignName={this.state.form.campaign.title}
                reminders={reminders}
                saveForm={this.saveClicked.bind(this)}
                saveSpecificationData={this.saveSpecificationData.bind(
                  this,
                  index
                )}
              />
            </div>
          );
        })}

        <Notes
          formType={this.props.formType}
          location={this.props.location}
          notesData={this.state.form.notes}
          campaignId={this.state.form.id}
          userRole={this.props.userRole}
          saveNotesData={this.saveNotesData.bind(this)}
        />

        {this.props.formType === "edit" ? (
          <Email
            campaignId={this.state.form.id}
            formType={this.props.formType}
            location={this.props.location}
            userRole={this.props.userRole}
            saveEmailData={this.saveEmailData.bind(this)}
          />
        ) : (
          ""
        )}

        <div className="Form_section">
          {this.props.userRole === "admin" && this.props.formType === "edit" ? (
            <Archive
              archived={this.state.archived}
              campaignId={this.state.form.id}
              switchArchivedFlag={this.switchArchivedFlag.bind(this)}
            />
          ) : (
            ""
          )}
        </div>

        <div className="Form_buttons">
          {this.state.saving === true ? (
            <span className="Form_saving">Please Wait...</span>
          ) : (
            <span>
              {this.props.userRole === "admin" ? (
                <button
                  className="button"
                  onClick={this.saveClicked.bind(this)}
                >
                  Save
                </button>
              ) : (
                ""
              )}
            </span>
          )}

          {this.props.formType !== "create" ? (
            <button className="button" onClick={this.print.bind(this)}>
              Print
            </button>
          ) : (
            ""
          )}

          {this.props.formType !== "create" ? (
            <a
              className="buttonLink"
              href={"/procurement/jobsheet/" + this.state.form.id}
            >
              Print Job Sheet
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
