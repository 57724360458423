import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import './Procurement.css';

import List from './List/List';
import Create from './Create/Create';
import Edit from './Edit/Edit';
import JobSheet from './JobSheet/JobSheet';
import ProductionSheet from './ProductionSheet/ProductionSheet';


export default class Procurement extends Component {
  static propTypes = {
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };


  render() {
    const pathParts = this.props.location.pathname.split('/');
    let style = 'Procurement';
    if (pathParts[2] === 'jobsheet') style='JobSheet_parent';
    return (
      <div className={style}>
        <Switch>

          <Route
            path="/procurement/create"
            exact={true}
            render={(props) =>
              <Create
                {...props}
                location={this.props.location}
                history={this.props.history}
                userRole={this.props.userRole} />
            }/>

          <Route
            path="/procurement/list"
            render={(props) =>
              <List
                {...props}
                location={this.props.location}
                userRole={this.props.userRole} />
            }/>

          <Route
            path="/procurement/view"
            render={(props) =>
              <Edit
                {...props}
                location={this.props.location}
                history={this.props.history}
                userRole={this.props.userRole}
                formType="view" />
            }/>

          <Route
            path="/procurement/edit"
            render={(props) =>
              <Edit
                {...props}
                location={this.props.location}
                history={this.props.history}
                userRole={this.props.userRole}
                formType="edit" />
            }/>

            <Route
              path="/procurement/jobsheet"
              render={(props) =>
                <JobSheet
                  {...props}
                  location={this.props.location} />
              }/>

            <Route
              path="/procurement/productionsheet"
              render={(props) =>
                <ProductionSheet
                  {...props}
                  location={this.props.location} />
              }/>


        </Switch>
      </div>
    );
  }
}
