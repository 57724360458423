import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Archive.css';
import { post } from '../../../../helpers/api';

export default class Archive extends Component {
  static propTypes = {
    campaignId: PropTypes.number.isRequired,
    archived: PropTypes.bool.isRequired,
    switchArchivedFlag: PropTypes.func.isRequired,
  };

  switchArchiveFlag() {
    post('/admin/procurement/postArchiveFlag', {
        campaignId: this.props.campaignId,
        archived: !this.props.archived,
      },
      (response) => {
        this.props.switchArchivedFlag();
      },
      (errors) => {
        console.error('ASet archive flag error');
      });
  }

  render() {
    return (
      <div className="Archive">
        <div className="Form_section Archive_form">
          <div className="Archive_title">
            Archive
            <input
              type="checkbox"
              checked={this.props.archived}
              onChange={this.switchArchiveFlag.bind(this)} />
          </div>

        </div>
      </div>
    );
  }
}
