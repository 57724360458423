import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { domainName } from '../../../../../clientConfig'
import EmailPanel from '../../EmailPanel/EmailPanel';

import './ReminderRow.css';

moment.locale('en-GB');

export default class ReminderRow extends Component {
  static propTypes = {
    campaignName: PropTypes.string,
    emailAddresses: PropTypes.array,
    formType: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    baseDateValue: PropTypes.string,
    dateValue: PropTypes.string,
    dateChanged: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    rowIndex: PropTypes.number.isRequired,
    setReminderEmailsOpenIndex: PropTypes.func.isRequired,
    setReminderMessageOpenIndex: PropTypes.func.isRequired,
    messageIsOpenIndex: PropTypes.number,
    emailsIsOpenIndex: PropTypes.number,
    messageEdited: PropTypes.func.isRequired,
    selectedEmailsEdited: PropTypes.func.isRequired,
  };

  state = {
    error: null,
  }

  dateRef = null; // reserved for the dateRef DatePicker

  pencilOpenerClicked() {
    if (this.props.messageIsOpenIndex === this.props.rowIndex) {
      this.props.setReminderMessageOpenIndex(null);
    } else {
      this.props.setReminderMessageOpenIndex(this.props.rowIndex);
    }
    this.props.setReminderEmailsOpenIndex(null);
  }

  emailOpenerClicked() {
    if (this.props.emailsIsOpenIndex === this.props.rowIndex) {
      this.props.setReminderEmailsOpenIndex(null);
    } else {
      this.props.setReminderEmailsOpenIndex(this.props.rowIndex);
    }
    this.props.setReminderMessageOpenIndex(null);
  }

  dateChanged(newDate) {
    const daysDifference = moment(this.props.baseDateValue).diff(newDate, 'days');

    const beforeNow = moment().diff(newDate, 'days');
    if (daysDifference < 0) {
      const dueDate = moment(this.props.baseDateValue).format('DD-MM-YYYY');
      const error = 'The date you selected is after the due by date of ' + dueDate;
      this.setState({error: error});
    } else if (beforeNow > 0) {
      const error = 'The date you selected has already passed';
      this.setState({error: error});
    } else {
      this.props.dateChanged(newDate);
      this.setState({error: null});
    }
  }

  changeMessage() {
    this.props.messageEdited(this.refs.message.value);
  }

  selectedEmailsChanged(emails) {
    this.props.selectedEmailsEdited(emails);
  }

  render() {
    return (
      <div className="ReminderRow">
        <div className="ReminderRow_remiderWrapper">
          <DatePicker
            id="dateRef"
            ref={dateRef => this.dateRef = dateRef}
            className="DateField_date"
            dateFormat="DD-MM-YYYY"
            locale="en-gb"
            readOnly
            selected={this.props.dateValue
              ? moment(this.props.dateValue)
              : undefined}
            onChange={this.dateChanged.bind(this)} />

          <button
            className="ReminderRow_pencilOpener"
            onClick={this.pencilOpenerClicked.bind(this)} >
            <i className="fa fa-pencil" aria-hidden="true"></i>
          </button>

          <button
            className="ReminderRow_userOpener"
            onClick={this.emailOpenerClicked.bind(this)} >
            <i className="fa fa-users" aria-hidden="true"></i>
          </button>

        </div>

        {this.props.dateValue ? (
          <div>
            Reminder is sent&nbsp;
            {moment(this.props.baseDateValue).diff(moment(this.props.dateValue), 'days')}
            &nbsp;Day(s) before due date
          </div>
        ) : '' }

        <div className="error">
          {this.state.error}
        </div>

        {this.props.messageIsOpenIndex === this.props.rowIndex ? (
          <div>
            <h5>Edit message</h5>
            <div>
              The following is automatically included:
            </div>
            <div className="ReminderRow_preMessage">
              This is a reminder for a due date on the {domainName} website.<br />
              Campaign Title: {this.props.campaignName}<br />

              {this.props.specificationName ? (
                <span>
                  Specification Title: {this.props.specificationName}<br />
                </span>
              ) : '' }

              Field Name: {this.props.fieldName}<br />
              Visit website<br />
            </div>
            <div>
              Write any additional messages below
            </div>
            <textarea
              rows="10"
              ref="message"
              value={this.props.message}
              onChange={this.changeMessage.bind(this)} />
          </div>
        ) : ''}


        {this.props.emailsIsOpenIndex === this.props.rowIndex ? (
          <EmailPanel
            selectedEmails={this.props.emailAddresses}
            onSelectedEmailsChanged={this.selectedEmailsChanged.bind(this) }/>
        ) : ''}

      </div>
    );
  }
}
