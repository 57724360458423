import React, { Component } from "react";
import PropTypes from "prop-types";
// import { BrowserRouter, Route } from 'react-router-dom';
import "./ProductionSheet.css";
import moment from "moment";
import { post } from "../../../helpers/api";

moment.locale("en-GB");

export default class ProductionSheet extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  };

  state = {
    campaignId: null,
    form: {},
    formLoaded: false,
    errors: null
  };

  componentWillMount() {
    this.fetchCampaigns(this.props);
  }

  componentDidUpdate() {
    if (this.state.formLoaded) {
      window.print();
    }
  }

  fetchCampaigns(props) {
    const pathParts = props.location.pathname.split("/");
    this.setState({
      campaignId: pathParts[3]
    });

    post(
      "/user/procurement/fetch",
      {
        campaignId: pathParts[3]
      },
      response => {
        this.setState({ errors: null });
        this.setState({
          form: response,
          formLoaded: true
        });
      },
      errors => {
        this.setState({ notFound: true });
      }
    );
  }

  render() {
    if (this.state.errors) {
      return (
        <div className="error">
          There was an error loading the campaign data
        </div>
      );
    }

    if (!this.state.formLoaded) {
      return <div>Loading...</div>;
    }

    const form = this.state.form;

    return (
      <div className="ProductionSheet">
        <h2>Campaign</h2>
        <table className="ProductionSheet_campaign">
          <thead>
            <tr>
              <td>Title</td>
              <td>Brand</td>
              <td>Client Contact</td>
              <td>Mail Date</td>
              <td>Paper / Pagination cut off date</td>
              <td>Data cut off date</td>
              <td>Doorstep Date</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{form.campaign.title}</td>
              <td>{form.campaign.brand}</td>
              <td>{form.campaign.clientContact}</td>
              <td>{moment(form.campaign.mailDate).format("DD-MM-YYYY")}</td>
              <td>
                {moment(form.campaign.paperCutOffDate).format("DD-MM-YYYY")}
              </td>
              <td>
                {moment(form.campaign.dataCutOffDate).format("DD-MM-YYYY")}
              </td>
              <td>{moment(form.campaign.doorstepDate).format("DD-MM-YYYY")}</td>
            </tr>
          </tbody>
        </table>

        <h2>Specifications</h2>
        <table className="ProductionSheet_specifications">
          <thead>
            <tr>
              <td>Title</td>
              <td>Bermuda Job No.</td>
              <td>Files received</td>
              <td>1st amends</td>
              <td>2nd amends</td>
              <td>Files passed</td>
              <td>Uploaded</td>
              <td>Signed off</td>
            </tr>
          </thead>
          <tbody>
            {form.specifications.map((specification, index) => {
              return (
                <tr key={index}>
                  <td>{specification.title}</td>
                  <td>{specification.bermudaJobNumber}</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
