import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Modal from 'react-modal';
import ReminderRow from './ReminderRow/ReminderRow';
import cloneextend from 'cloneextend';

import './DateField.css';

moment.locale('en-GB');

export default class DateField extends Component {
  static propTypes = {
    campaignId: PropTypes.number,
    campaignName: PropTypes.string,
    fieldName: PropTypes.string,
    formType: PropTypes.string.isRequired,
    dateValue: PropTypes.string,
    onDateChanged: PropTypes.func.isRequired,
    showMessage: PropTypes.bool.isRequired,
    specificationName: PropTypes.string,
    reminders: PropTypes.object,
    remindersChanged: PropTypes.func,
  };

  state = {
    messageModalOpen: false,
    senderModalOpen: false,
    reminderDates: [],
    reminderEmailsOpenIndex: null,
    reminderMessageOpenIndex: null,
    messages: [],
    emailAddresses: [],
  }

  dateRef = null; // reserved for the dateRef DatePicker

  componentWillMount() {
    if (this.props.reminders
      && this.props.reminders.date1
      && this.props.reminders.date1.dateToSend
    ) {
      this.reloadDatesFromParent(this.props);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if ((this.props.dateValue !== nextProps.dateValue
        || this.props.campaignId !== nextProps.campaignId)
      && nextProps.reminders
      && nextProps.reminders.date1
      && nextProps.reminders.date1.dateToSend
    ) {
      this.reloadDatesFromParent(nextProps);
    }
  }

  reloadDatesFromParent(props) {
    const reminderDates = [];
    reminderDates.push(props.reminders.date1.dateToSend);
    reminderDates.push(props.reminders.date2.dateToSend);
    reminderDates.push(props.reminders.date3.dateToSend);

    const messages = [];
    messages.push(props.reminders.date1.message);
    messages.push(props.reminders.date2.message);
    messages.push(props.reminders.date3.message);

    const emailAddresses = [];
    emailAddresses.push(props.reminders.date1.emails);
    emailAddresses.push(props.reminders.date2.emails);
    emailAddresses.push(props.reminders.date3.emails);
    this.setState({
      reminderDates,
      messages,
      emailAddresses,
    });
  }

  dateGraphicClicked() {
    this.dateRef.setOpen(true);
  }

  messageOpenerClicked() {
    this.setState({messageModalOpen: true});
  }

  messageSenderClicked() {
    this.setState({senderModalOpen: true});
  }

  closeMessageModalClicked() {
    this.setState({messageModalOpen: false});
    this.remindersChanged(this.state);
  }

  remindersChanged(state) {
    this.props.remindersChanged({
      date1: {
        emails: state.emailAddresses[0],
        message: state.messages[0],
        dateToSend: state.reminderDates[0],
      },
      date2: {
        emails: state.emailAddresses[1],
        message: state.messages[1],
        dateToSend: state.reminderDates[1],
      },
      date3: {
        emails: state.emailAddresses[2],
        message: state.messages[2],
        dateToSend: state.reminderDates[2],
      },
    });
  }

  reminderDateChanged(index, newDate) {
    const reminderDates = cloneextend.clone(this.state.reminderDates);
    reminderDates[index] = newDate.format('YYYY-MM-DD');
    this.setState({ reminderDates });
    const state = cloneextend.clone(this.state);
    state.reminderDates = reminderDates;
    this.remindersChanged(state);
  }

  setReminderMessageOpenIndex(index) {
    this.setState({reminderMessageOpenIndex: index});
  }

  setReminderEmailsOpenIndex(index) {
    this.setState({reminderEmailsOpenIndex: index});
  }

  messageEdited(rowIndex, message) {
    const messages = [ ...this.state.messages];
    messages[rowIndex] = message;
    this.setState({ messages });
    const state = cloneextend.clone(this.state);
    state.messages = messages;
    this.remindersChanged(state);
  }

  selectedEmailsEdited(rowIndex, emails) {
    const emailAddresses = [ ...this.state.emailAddresses ];
    emailAddresses[rowIndex] = emails;
    this.setState({ emailAddresses });
    const state = cloneextend.clone(this.state);
    state.emailAddresses = emailAddresses;
    this.remindersChanged(state);
  }

  render() {
    const editable = this.props.formType === 'edit'
      || this.props.formType === 'create';
    return (
      <div className="DateField">
        {editable ? (
          <DatePicker
            id="dateRef"
            ref={dateRef => this.dateRef = dateRef}
            className="DateField_date"
            dateFormat="DD-MM-YYYY"
            locale="en-gb"
            readOnly
            selected={this.props.dateValue
              ? moment(this.props.dateValue)
              : undefined}
            onChange={this.props.onDateChanged.bind(this)} />
        ) : (
          <input
            value={moment(this.props.dateValue).format('DD-M-YYYY') || ''}
            readOnly
            className="DateField_date"
            type="text" />
        )}

        {editable ? (
          <img
            src="/images/cal.gif"
            alt="Open Calendar"
            onClick={this.dateGraphicClicked.bind(this)}
            className="DateField_dateButton" />
        ) : ''}

        {editable && this.props.showMessage && this.props.dateValue ? (
          <button
            className="DateField_messageOpener"
            onClick={this.messageOpenerClicked.bind(this)} >
            <i className="fa fa-envelope" aria-hidden="true"></i>
          </button>
        ) : ''}

        <Modal
          isOpen={this.state.messageModalOpen}
          onAfterOpen={()=>{}}
          onRequestClose={()=>{}}
          className={{
            base: 'messageModalBase',
          }}
          overlayClassName={{
            base: 'messageModalBaseOverlay',
          }}
          contentLabel="Modal">
          <h2>Reminders</h2>

          {this.state.reminderDates.map((row, index) => {
            return (
              <ReminderRow
                emailAddresses={this.state.emailAddresses[index]}
                key={index}
                rowIndex={index}
                campaignName={this.props.campaignName}
                fieldName={this.props.fieldName}
                specificationName={this.props.specificationName}
                setReminderEmailsOpenIndex={this.setReminderEmailsOpenIndex.bind(this)}
                setReminderMessageOpenIndex={this.setReminderMessageOpenIndex.bind(this)}
                message={this.state.messages[index]}
                messageEdited={this.messageEdited.bind(this, index)}
                messageIsOpenIndex={this.state.reminderMessageOpenIndex}
                emailsIsOpenIndex={this.state.reminderEmailsOpenIndex}
                baseDateValue={this.props.dateValue}
                formType={this.props.formType}
                selectedEmailsEdited={this.selectedEmailsEdited.bind(this, index)}
                dateValue={this.state.reminderDates[index]}
                dateChanged={this.reminderDateChanged.bind(this, index)} />
            );
          })}

          <div className="DateField_modalFooter">
            <button
              className="DateField_closeButton"
              onClick={this.closeMessageModalClicked.bind(this)}>
              Close
            </button>
          </div>
        </Modal>

      </div>
    );
  }
}
