import React, { Component } from "react";
import PropTypes from "prop-types";
import "./MailHouse.css";
import AutoComplete from "../AutoComplete/AutoComplete";

export default class MailHouse extends Component {
  static propTypes = {
    autocompleteData: PropTypes.object.isRequired,
    campaignId: PropTypes.number,
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    formType: PropTypes.string.isRequired,
    mailHouseData: PropTypes.object,
    saveMailHouseData: PropTypes.func.isRequired
  };

  state = {
    mailHouse: "",
    contact: "",
    postage: "",
    contents: "",
    packWeight: "",
    quantity: "",
    quote: ""
  };

  componentWillMount() {
    this.setInitialState(this.props);
  }

  componentWillUpdate(nextProps) {
    if (this.props.campaignId !== nextProps.campaignId) {
      this.setInitialState(nextProps);
    }
  }

  setInitialState(props) {
    if (props.campaignId !== null) {
      this.setState({
        mailHouse: props.mailHouseData.mailHouse,
        contact: props.mailHouseData.contact,
        postage: props.mailHouseData.postage,
        contents: props.mailHouseData.contents,
        packWeight: props.mailHouseData.packWeight,
        quantity: props.mailHouseData.quantity,
        quote: props.mailHouseData.quote
      });
    }
  }

  mailHouseChanged(mailHouse) {
    const state = { ...this.state };
    state.mailHouse = mailHouse;
    this.props.saveMailHouseData(state);
    this.setState({ mailHouse: mailHouse });
  }

  contactChanged(contact) {
    const state = { ...this.state };
    state.contact = contact;
    this.props.saveMailHouseData(state);
    this.setState({ contact: contact });
  }

  postageChanged(postage) {
    const state = { ...this.state };
    state.postage = postage;
    this.props.saveMailHouseData(state);
    this.setState({ postage: postage });
  }

  contentsChanged(contents) {
    const state = { ...this.state };
    state.contents = contents;
    this.props.saveMailHouseData(state);
    this.setState({ contents: contents });
  }

  packWeightChanged(packWeight) {
    const state = { ...this.state };
    state.packWeight = packWeight;
    this.props.saveMailHouseData(state);
    this.setState({ packWeight: packWeight });
  }

  quantityChanged(quantity) {
    const state = { ...this.state };
    state.quantity = quantity;
    this.props.saveMailHouseData(state);
    this.setState({ quantity: quantity });
  }

  quoteChanged(quote) {
    const state = { ...this.state };
    state.quote = quote;
    this.props.saveMailHouseData(state);
    this.setState({ quote: quote });
  }

  render() {
    const readOnly = this.props.formType === "edit" || "create" ? false : true;
    let autocompleteData;
    if (this.props.autocompleteData.mailHouse) {
      autocompleteData = this.props.autocompleteData.mailHouse;
    }
    return (
      <div className="MailHouse">
        <h2 className="Form_sectionTitle">Mail House</h2>
        <div className="Form_section">
          <table className="Form_table" cellPadding="0" cellSpacing="0">
            <tbody>
              <tr>
                <td width="85">Mail House</td>
                <td width="85">
                  <AutoComplete
                    data={autocompleteData ? autocompleteData.mailHouse : []}
                    value={this.state.mailHouse || ""}
                    onChange={this.mailHouseChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.mailHouse}
                  </span>
                </td>
                <td width="68">Contact</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.mailHouseContact : []
                    }
                    value={this.state.contact || ""}
                    onChange={this.contactChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                </td>
                <td>Postage</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.mailHousePostage : []
                    }
                    value={this.state.postage || ""}
                    onChange={this.postageChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                </td>
              </tr>
              <tr>
                <td>Contents</td>
                <td colSpan="5">
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.mailHouseContents : []
                    }
                    value={this.state.contents || ""}
                    onChange={this.contentsChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputFullWidth"
                  />
                  <span className="Form_printInput">{this.state.contents}</span>
                </td>
              </tr>
              <tr>
                <td>Pack Weight</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData
                        ? autocompleteData.mailHousePackWeight
                        : []
                    }
                    value={this.state.packWeight || ""}
                    onChange={this.packWeightChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">
                    {this.state.packWeight}
                  </span>
                </td>
                <td>Quantity</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.mailHouseQuantity : []
                    }
                    value={this.state.quantity || ""}
                    onChange={this.quantityChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.quantity}</span>
                </td>
                <td>Quote</td>
                <td>
                  <AutoComplete
                    data={
                      autocompleteData ? autocompleteData.mailHouseQuote : []
                    }
                    value={this.state.quote || ""}
                    onChange={this.quoteChanged.bind(this)}
                    readOnly={readOnly}
                    className="Form_inputSmall"
                  />
                  <span className="Form_printInput">{this.state.quote}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
