import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
import './List.css';
import moment from 'moment';

import NotFound from '../../NotFound/NotFound';

import { post } from '../../../helpers/api';

moment.locale('en-GB');

export default class List extends Component {
  static propTypes = {
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
  };

  state = {
    campaigns: [],
    notFound: false,
    title: '',
  };

  validSelections = [
    'all',
    'grj',
    'rd',
    'gcj',
    'usa',
  ];

  componentWillMount() {
    this.fetchCampaigns(this.props);
  }

  fetchCampaigns(props) {
    const pathParts = props.location.pathname.split('/');
    this.setState({
      selection: pathParts[3],
      campaigns: [],
    });

    let selection;
    let title;
    switch(pathParts[3]) {
      case 'grj':
        selection = 'GRJ';
        title = 'Great Rail Journeys Procurement';
        break;
      case 'rd':
        selection = 'RD';
        title = 'RD Procurement';
        break;
      case 'gcj':
        selection = 'GCJ';
        title = 'GCJ Procurement';
        break;
      case 'usa':
        selection = 'USA';
        title = 'USA Procurement';
        break;
      default:
        selection = 'all';
        title = 'Procurement Archive';
    }
    this.setState({title});

    post('/user/procurement/fetchList', {
        'selection': selection,
      },
      (response) => {
        this.setState({campaigns: response});
      },
      (errors) => {
        this.setState({notFound: true});
      });
  }

  componentWillReceiveProps(nextProps) {
    const pathParts = nextProps.location.pathname.split('/');
    this.setState({selection: pathParts[3]});
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.fetchCampaigns(nextProps);
    }
  }

  deleteCampaign(campaignId, title, event) {
    event.preventDefault();

    confirmAlert({
      title: 'Confirm Deletion',
      childrenElement: () => (
        <div style={{fontSize: '16px'}}>
          Are you sure you wish to delete the <strong>{title}</strong> campaign?'
        </div>
      ),
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      onConfirm: () => {
        post('/admin/procurement/deleteCampaign', {
            campaignId,
          },
          (response) => {
            this.fetchCampaigns(this.props);
          },
          (errors) => {
            console.error('Failed to delete campaign');
          });
      },
      onCancel: () => {},
    })
  }

  render() {
    if (this.validSelections.indexOf(this.state.selection) === -1
      || this.state.notFound === true
    ) {
      return (
        <BrowserRouter>
          <Route component={NotFound}/>
        </BrowserRouter>
      );
    }

    if (this.state.campaigns === null) {
      return <div>Loading</div>;
    }

    return (
      <div className="List">
        <h2>
          {this.state.title}
        </h2>
        <div className="List_wrapper">
          <table className="List_table" cellPadding="0" cellSpacing="0">
            <tbody>
              {this.state.campaigns.map((campaign, index) => {
                const campaignMoment  = moment(campaign.mailDate)
                const campaignDate = campaign.mailDate ?
                  campaignMoment.format('DD-MM-YYYY') : '';
                if (this.props.userRole !== 'admin' && campaign.archived) {
                  return;
                }
                const archivedClass = campaign.archived === 1 ? "archived" : "";
                return (
                  <tr key={index} className={archivedClass}>
                    <td>
                      {campaignDate}
                    </td>
                    <td>
                      {campaign.title}
                    </td>
                    <td>
                      <a href={'/procurement/view/' + campaign.id}>
                        View
                      </a>
                    </td>
                    <td>
                      {this.props.userRole === 'admin' ? (
                        <a href={'/procurement/edit/' + campaign.id}>
                          Edit
                        </a>
                      ) : ''}
                    </td>
                    <td>
                      {this.props.userRole === 'admin' ? (
                        <a
                          href=""
                          onClick={this.deleteCampaign.bind(
                            this,
                            campaign.id,
                            campaign.title
                          )} >
                          Delete
                        </a>
                      ) : ''}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
