import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { BrowserRouter, Route } from 'react-router-dom';
import './Create.css';

import Form from '../Form/Form';
// import { post } from '../../helpers/api';

export default class Create extends Component {
  static propTypes = {
    userRole: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  render() {

    return (
      <div className="Create">
        <Form
          formType="create"
          location={this.props.location}
          history={this.props.history}
          userRole={this.props.userRole} />
      </div>
    );
  }
}
