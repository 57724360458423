import React, { Component } from 'react';
// import './App.css';


export default class NotFound extends Component {
  render() {
    return (
      <div>
        Page Not Found
      </div>
    );
  }
}
